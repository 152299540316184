import React, { useState, useEffect, useCallback, useContext } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { useParams } from "react-router-dom";
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import CategoryCardGroup from 'src/components/CategoryCardGroup';
import categories from '../../assets/data/categoriesAll.json'
import ContractorView from '../../components/ContractorView/ContractorView';
import { SelectedLocationContext } from '../../context/selectedLocation'
import { getContractorsForStateAndVertical } from 'src/lib/apiClient';
import Box from '@mui/material/Box';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { postSignup } from '../../lib/apiClient';
import PositionedSnackbar from 'src/shared/PositionedSnackbar';
import Content from '../Content';
import { useQuery } from '@tanstack/react-query';
import { QueryStatus } from 'src/types';
import Loading from 'src/components/Loading';

const unavailableVerticals = [
  'Solar',
  'Batteries',
  'EV Charging',
  'Efficiency',
  'Lighting',
  'Yard',
  'Compost'
]

const style = {
  position: 'fixed',
  transform: 'translate(12%)',
  width: '80%',
  height: '70%',
  border: '2px solid #000',
  boxShadow: 10,
  borderRadius: '5px',
  background: 'rgba(0,0,0,.2)'
};

const regexp = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/);
const QUERY_KEY_CONTRACTORS_VERTICAL = 'contractorStateVertical'

export default function Learn() {
  // context
  const {
    selectedLocation
  } = useContext(SelectedLocationContext);
  // state
  const { slug } = useParams();
  const [currentCategory, setCurrentCategory] = useState(categories[0]);
  const [learning, setLearning] = useState(false);
  const [learnButtonText, setButtonText] = useState('Learn More')
  const { status, data: contractors, error } = useQuery({
    queryKey: [QUERY_KEY_CONTRACTORS_VERTICAL, selectedLocation.zip, slug, currentCategory],
    queryFn: () => getContractorsForStateAndVertical(selectedLocation.zip, slug),
  })
  const [email, setEmail] = useState('Your Email');
  const [emailIsDirty, setEmailDirty] = useState(false);
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [success, setSuccess] = useState(false);

  function handleEmailChange(event: { target: { value: React.SetStateAction<string>; }; }) {
    setEmailDirty(true)
    setEmail(event.target.value)
    if (regexp.test(email)) {
      setEmailIsValid(true)
    } else {
      setEmailIsValid(false)
    }
  }

  function clearDefaultEmail() {
    if (!emailIsDirty) setEmail('')
  }

  // handlers
  const handleCategoryChange = useCallback(() => {
    const category = categories.find(category => category.slug === slug)
    if (category) {
      setCurrentCategory(category);
    }
  }, [slug]);

  useEffect(() => {
    handleCategoryChange();
  }, [slug, handleCategoryChange])

  useEffect(() => {}, [contractors])

  const titleTextStyling = {
    flexGrow: 1,
    marginBottom: '2rem',
    color: '#B96B37',
    fontFamily: 'serif',
    fontSize: 48,
    fontWeight: '300'
  }

  function toggleLearning() {
    setLearning(!learning);

    setButtonText(learnButtonText === 'Learn More' ? 'Back' : 'Learn More');
  }

  async function handleSignup(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, vertical: string) {
    event.preventDefault()
    const response = await postSignup(email, vertical)
    if (response.status === 200) {
      setSuccess(true)
      setEmail('Your Email')
      setEmailDirty(false)
      const timer = setTimeout(() => {
        setSuccess(false)
      }, 5000);
      return () => clearTimeout(timer);
    }
  }

  if (unavailableVerticals.includes(currentCategory.title)) {
    return (

      <Grid container spacing={2}>
        <Grid xs={12} columnSpacing={{ xs: 1, sm: 2, md: 3 }} marginTop="2rem">
          <CategoryCardGroup urlPath='learn' />
        </Grid>
        <Grid xs={12} columnSpacing={{ xs: 1, sm: 2, md: 3 }} spacing={8}>
          <Box sx={style} textAlign='center'>
            <Typography id="modal-modal-title" variant="h2" fontWeight={400} marginTop={25}>
              More info on {currentCategory.title} is coming soon!
            </Typography>
            <Typography id="modal-modal-description" variant="h6" sx={{ mt: 1, mb: 1 }}>
              Enter your email below and we'll keep you in the loop
            </Typography>
            <div>
              <input type="textarea"
                name="textValue"
                value={email}
                style={{ borderRadius: '25px', width: '45%', alignContent: 'center', fontSize: '200%', fontFamily: 'inherit', textAlign: 'center' }}
                onChange={handleEmailChange}
                onClick={clearDefaultEmail}
              />
              <Button disabled={!emailIsValid} color={'inherit'} style={{ paddingBottom: '1.5rem' }} onClick={(e) => handleSignup(e, currentCategory.title)}><ArrowForwardOutlinedIcon fontSize={'large'} /></Button>
            </div>
          </Box>
        </Grid>
        {success && <PositionedSnackbar message={'Thanks! We will you email you when we have contractor info for you!'} />}
      </Grid>


    )
  }
  
  return (
    <Grid container spacing={3}>
      <Grid xs={12} columnSpacing={{ xs: 1, sm: 2, md: 3 }} margin={{ xs: '0 0 0 0' }}>
        <CategoryCardGroup urlPath='learn' />
      </Grid>
      <Grid xs={12} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Stack direction="column" alignItems="center" justifyContent="center" spacing={{ xs: 0, sm: 4 }} marginTop="1rem" marginBottom={{ xs: 0, sm: "1rem" }} sx={{ flexDirection: { xs: "column", md: "row" } }}>

          <Grid xs={12} sm={3} spacing={2} justifyContent="center" rowSpacing={4} order={{ xs: 2, sm: 2, md: 1, lg: 1 }}>
            
            <Typography variant="h4" component="div" gutterBottom sx={titleTextStyling}  marginLeft={{ xs: 2, sm: 0 }}>
              {currentCategory.title}
            </Typography>

            <Typography variant="body1" component="div" gutterBottom sx={{ flexGrow: 1, marginBottom: '2rem', marginLeft: { xs: 2, sm: 0 } }}>
              {currentCategory.learnHeader}
            </Typography>
            <FormControl>
              <Button
                color="primary"
                variant="outlined"
                size="large"
                sx={{ borderRadius: 5, marginLeft: { xs: 2, md: 0 } }}
                onClick={toggleLearning}
              >
                {learnButtonText}
              </Button>
            </FormControl>
          </Grid>

          <Grid xs={12} sm={4} order={{ xs: 1, sm: 1, md: 2, lg: 2 }}>
            <img
              src={`${process.env.PUBLIC_URL}/images/${currentCategory.utility_image}`}
              alt={`${currentCategory.description}`} style={{
                width: "100%", height: "100%", borderRadius: '3%',
              }}
            />
          </Grid>
          
          
          
        </Stack>
        <Grid order={3} md={6}>
            {learning ? 
              <Content/> : 
              status === QueryStatus.PENDING ? (
          
                <Loading size={100} position='relative'/>
              ) : status === QueryStatus.ERROR ? (
                error.message
              ) : (
                <ContractorView contractors={contractors} vertical={currentCategory.title}/>
              )
            }
          </Grid>
      </Grid>
    </Grid >
  );
}