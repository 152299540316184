import React, {useEffect, useState} from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Stack from '@mui/material/Stack';
import { Location } from 'src/types'
import {getSupportedZipcodes} from '../../lib/apiClient'
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';


const InputLabel = styled('label', {
  name: 'InputLabel',
  slot: 'root',
})(({ theme }) => ({
  color: grey[900], 
}));

export default function LocationSelect({ value, onChange, id}: {value: Location, onChange: Function, id: string}) {
    const [options, setOptions] = useState<readonly Location[]>([]);
    
    useEffect(() => {
        (async () => {
            const supportedZipcodes = await getSupportedZipcodes()
            const sortedSupportedZipcodes = supportedZipcodes.sort(
                (a, b) => -b.zip[0].localeCompare(a.zip[0])
              )
            setOptions(sortedSupportedZipcodes)
        })()
    }, []);

  return (
      <Autocomplete
        onChange={(event: React.SyntheticEvent, item: Location) => {
          onChange(item)
        }}
        value={value}
        options={options}
        groupBy={(option) => option.state_name}
        getOptionLabel={(option) => option.zip}
        id={id}
        isOptionEqualToValue={(option, value) => option.zip === value.zip}
        disableClearable
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            fullWidth
            required
            sx={{
              width: '20ch',
            }}
          />
        )}
        ListboxProps={
          {
            style:{
                maxHeight: '180px'
            }
          }
        }
      />
  )
}