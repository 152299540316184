import * as React from 'react';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

export interface State extends SnackbarOrigin {
    open: boolean;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  

export default function PositionedSnackbar({ message }: { message: String }) {
    // This is a temporary hack until we start passing state around components
    const [state, setState] = React.useState<State>({
        open: true,
        vertical: 'top',
        horizontal: 'center',
    });
    const { open } = state;

    const handleClose = () => {
        setState({ ...state, open: false });
    };

    return (
        <div>
            {/* <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={open}
                onClose={handleClose}
                message={`${message}`}
                key={vertical + horizontal}
            /> */}

            <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
}

