import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import Typography from '@mui/material/Typography';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const selectCategories = [
  'Heat Pumps',
  'Electric Water Heaters',
  'Induction Stoves',
  'Upgraded Electrical',
]

export type SwipeableTextMobileStepperProps = {textStyling: object}

export default function SwipeableTextMobileStepper({textStyling}: SwipeableTextMobileStepperProps) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <>
      <Typography variant='h3' sx={textStyling}>{selectCategories[activeStep]}</Typography>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        interval={1350}
      >
        {selectCategories.map((category, index) => (
          <div key={category}/>
        ))}
      </AutoPlaySwipeableViews>
    </>
  );
}