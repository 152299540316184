import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { useParams } from "react-router-dom";
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import categories from '../../assets/data/categoriesAll.json'
import StarIcon from '@mui/icons-material/Star';
import { Box, ImageList, ImageListItem } from '@mui/material';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import { postContractorQuote, getContractor } from '../../lib/apiClient';
import { ContractorQuote, QueryStatus } from 'src/types';
import ContractorQuoteForm from 'src/components/ContractorQuoteForm';
import BackButton from 'src/components/BackButton';
import Loading from 'src/components/Loading';
import PositionedSnackbar from 'src/shared/PositionedSnackbar';
import { useQuery } from '@tanstack/react-query';

function srcset(url: string, size: number, rows = 1, cols = 1) {
  return {
    src: url,
    srcSet: `${url}?w=${size * cols}&h=${size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
  };
}

const placeholderImage = {
  url: `${process.env.PUBLIC_URL}/images/home_learn_placeholder.jpg`,
  title: 'default',
}

const QUERY_KEY_CONTRACTOR_PROFILE = 'contractorProfile'

export default function ContractorProfile() {

  const { slug } = useParams();
  // helpers
  function getImageForCategory(category: string) {
    return categories.find(x => x.title === category)?.picture || 'Solar.png'
  }
  // state
  const [success, setSuccess] = useState(false);
  const [chatWidth, setChatWidth] = useState(undefined);
  const [sidebarTop, setSidebarTop] = useState(undefined);
  const { status, data: currentContractor, error } = useQuery({
    queryKey: [QUERY_KEY_CONTRACTOR_PROFILE, slug],
    queryFn: () => getContractor(slug),
  })


  const onSubmit = async (formData: ContractorQuote) => {
    // console.log(formData)
    const { firstName, lastName, address, email, phoneNumber } = formData
    const response = await postContractorQuote(firstName, lastName, address, email, phoneNumber, currentContractor)
    if (response.status === 200) {
      setSuccess(true)
      const timer = setTimeout(() => {
        setSuccess(false)
      }, 5000);
      return () => clearTimeout(timer);
    }
  }

  useEffect(() => {

    if(status !== QueryStatus.SUCCESS) return;

    const chatEl = document.querySelector('.form-channel')!.getBoundingClientRect();
    // @ts-ignore
    setChatWidth(chatEl.width);

    // @ts-ignore
    setSidebarTop(chatEl.top);
  }, [status]);

  useEffect(() => {
    if (!sidebarTop) return;

    const scrollContainer = document.querySelector<HTMLElement>('.MuiGrid-container')!;

    const isSticky = () => {
      const chatEl = document.querySelector<HTMLElement>('.contact-form');
      const scrollTop = scrollContainer.scrollTop;
      if (chatEl) {
        // @ts-ignore
        if (scrollTop >= sidebarTop) {
          chatEl.classList.add('is-sticky');
          // @ts-ignore
          chatEl.style.width = chatWidth;
        } else {
          chatEl.classList.remove('is-sticky');
        }
      }
    };

    scrollContainer.addEventListener('scroll', isSticky);
    return () => {
      scrollContainer.removeEventListener('scroll', isSticky);
    };
  }, [sidebarTop, chatWidth]);

  return (

    <Grid container spacing={1} sx={{ margin: { xs: "1rem 1rem", md: "1rem 4rem"} }}>
      <BackButton/>
      
      <Grid xs={12} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      {status === QueryStatus.PENDING ? (
          
          <Loading size={100} />
        ) : status === QueryStatus.ERROR ? (
          error.message
        ) : (
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={4} marginBottom="5rem">
          <Grid xs={12} spacing={2} justifyContent="center" rowSpacing={3}>
            <Grid xs={12}>
              <Typography variant="h4" component="div" gutterBottom sx={{ flexGrow: 1, margin: '1rem' }}>
                {currentContractor.company_name}
              </Typography>

              <ImageList
                variant="quilted"
                cols={4}
                rowHeight={250}
              >
                { currentContractor.photos?.length === 0 ?
                  
                    <ImageListItem key={`contractor-photo-0`} cols={1} rows={1}>
                      <img
                        style={{ borderRadius: '10px' }}
                        {...srcset(placeholderImage.url, 121, 1, 1)}
                        alt={`${placeholderImage.title}`}
                        loading="lazy"
                      />
                    </ImageListItem>
                  
                  :
                currentContractor?.photos?.slice(0,4).map((photo: { url: any; }, index: any) => (
                  <Box
                    component="img"
                    key={`contractor-photo-${index}`} 
                    sx={{
                      borderRadius: '8px',
                      content: {
                        // can use different photos later if we save multiple sizes of an image :) 
                        xs: `url(${photo.url})`, 
                      },
                      height: 240,
                      width: 350,
                      maxHeight: { xs: 300, md: 300 },
                      maxWidth: { xs: 300, md: 350 },
                      objectFit: 'cover'
                    }}
                    alt="Logo"/>
                ))}
              </ImageList>
            </Grid>
            <Grid container direction={'row'} xs={12}>
              <Grid xs={12} md={5}>
                <Stack sx={{ marginLeft: { xs: '.5rem', md: '4rem'} }}>
                  <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
                    {currentContractor.description}
                  </Typography>

                  <Typography variant="body1" component="div" gutterBottom sx={{ flexGrow: 1, marginBottom: '2rem' }}>
                    {currentContractor?.address?.length === 0 ? "No Address Available" : currentContractor.address}
                  </Typography>

                  <Typography variant="h4" component="div" sx={{ flexGrow: 1, paddingBottom: { xs: '1rem', md: '2rem'} }}>
                    Services provided
                  </Typography>

                  <Grid container xs={12}>
                    {
                      currentContractor.category?.map((category:any, index: number) => {
                        return (
                          <Grid xs={12} key={`contractor-category-${index}`}>
                            <div style={{ borderRadius: '5px', display: 'flex', alignItems: 'center', marginLeft: "-1rem" }}>
                              <img
                                src={`${process.env.PUBLIC_URL}/images/${getImageForCategory(category)}`}
                                alt={`${category}`} style={{
                                  width: "100px", height: "100px", borderRadius: '3%'
                                }}
                              />
                              
                              <Typography style={{ fontWeight: 'bold' }}>{category}<br/> Permits Recorded: {currentContractor.permits.filter((permit: { vertical: any; }) => permit.vertical == category).length} <InventoryOutlinedIcon/> </Typography>
                            </div>
                          </Grid>
                        )
                      })
                    }
                  </Grid>

                  <Typography variant="h4" component="div" sx={{ flexGrow: 1, paddingBottom: { xs: '1rem', md: '2rem'}, paddingTop: { xs: '1rem', md: '2rem'} }}>
                    Payment
                  </Typography>

                  <Grid container xs={12}>
                    <Grid xs={12} sm={6}>
                      <Typography variant="body1" component="div" gutterBottom sx={{ flexGrow: 1 }}>
                        <b>Rebates + Incentives:</b> Yes
                      </Typography>
                    </Grid>
                    <Grid xs={12} sm={6}>
                      <Typography variant="body1" component="div" gutterBottom sx={{ flexGrow: 1 }}>
                        <b>Financing:</b> Yes
                      </Typography>
                    </Grid>
                  </Grid>

                  <Typography variant="h4" component="div" sx={{ flexGrow: 1, paddingBottom: '2rem', paddingTop: '2rem' }}>
                    Reviews
                  </Typography>
                  

                  { currentContractor.reviews?.length === 0 ?
                    <Typography variant="body1" component="div" gutterBottom sx={{ flexGrow: 1 }}>
                      No Reviews just yet - check again soon.
                    </Typography> : 
                    currentContractor.reviews?.map((el: any, index: number) => {
                      return (
                        <div style={{ paddingLeft: '4vw' }}>
                          <Grid xs={12} sx={{ display: 'inline-block' }} key={`contractor-review-${index}`}>
                            <Grid xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                              <Typography variant="h5" component="div">
                                {el.author_name}
                              </Typography>

                              {
                                [...Array(Number(el.rating)).keys()].map(() => {
                                  return (
                                    <StarIcon />
                                  )
                                })
                              }

                            </Grid>
                            <Grid xs={12}>
                              <Typography>
                                {el.text}
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                      )
                    })}
                  
                </Stack>
              </Grid>
              <Grid className="form-channel" xs={12} md={6} sx={{ marginLeft: {md: '3rem'}, paddingTop: '20px !important' }}>
                <ContractorQuoteForm title={ `Contact ${currentContractor.company_name || ''}`} onSubmit={onSubmit} />
              </Grid>
            </Grid>
          </Grid>
        </Stack>
        )}
      </Grid>
      
      {success && <PositionedSnackbar message={`Thanks! We sent your message to ${currentContractor.company_name}!`}/>}
    </Grid>
  );
}