import { ContractorInfo, ContractorsByCategory } from "src/types";

export const formatCityContractors = (contractors: ContractorInfo[]): ContractorsByCategory => {
    const uniqueCategories = [...new Set(contractors.map(contractor => contractor.category).flat())]
    const contractorsByCategory: ContractorsByCategory = uniqueCategories.reduce((acc, currentCategory) => {
        const contractorsForCategory = contractors.filter(contractor => contractor.category.includes(currentCategory))
        if (!acc[currentCategory]) {
            acc[currentCategory] = contractorsForCategory
        }
        return acc
    }, {} as ContractorsByCategory)

    return contractorsByCategory
}
