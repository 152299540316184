import * as React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import {
    Box,
    Grid,
    TextField,
    Typography,
    Button,
} from '@mui/material'
import { ContractorSignup } from '../../types'

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().required('Email is required'),
})

const contractorQuoteDefaultValues = {
    name: '',
    businessName: '',
    email: '',
}


export default function ContractorSignupForm({ onSubmit }: { onSubmit: any }) {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<ContractorSignup>({
        resolver: yupResolver(validationSchema),
        defaultValues: contractorQuoteDefaultValues,
    })
    const onSubmitWrapper = async (data: any) => {
        await onSubmit(data)
        // TODO: handle failures
        reset(contractorQuoteDefaultValues)
    }

    return (
        <Box px={3} py={2} className="contact-form" style={{ height: 'auto', paddingTop: '20px !important' }}>
            <Typography variant="h4" component="div" sx={{ flexGrow: 1, marginBottom: '1rem', marginLeft: '1rem' }}>
                Ready to get started?
            </Typography>
            <Typography variant="h5" component="div" sx={{ flexGrow: 1, marginBottom: '1rem', marginLeft: '1rem' }}>
                You will be sent an onboarding email with next steps!
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={6} >
                    <TextField
                        size="small"
                        id="name"
                        label="Name"
                        fullWidth
                        margin="dense"
                        {...register('name')}
                        error={errors.name ? true : false}
                        sx={{
                            marginTop: '-0.1px',
                        }}
                    />
                    <Typography variant="inherit" color="textSecondary">
                        {errors.name?.message}
                    </Typography>
                </Grid>
                <Grid item xs={6} >
                    <TextField
                        size="small"
                        id="businessName"
                        label="Business Name"
                        fullWidth
                        margin="dense"
                        {...register('businessName')}
                        error={errors.businessName ? true : false}
                        sx={{
                            marginTop: '-0.1px',
                        }}
                    />
                    <Typography variant="inherit" color="textSecondary">
                        {errors.businessName?.message}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        size="small"
                        id="email"
                        label="Your Email"
                        fullWidth
                        margin="dense"
                        {...register('email')}
                        error={errors.email ? true : false}
                        sx={{
                            marginTop: '-0.1px',
                        }}
                    />
                    <Typography variant="inherit" color="textSecondary">
                        {errors.email?.message}
                    </Typography>
                </Grid>
            </Grid>
            <Box display="flex"
                justifyContent="flex-end"
                alignItems="flex-end">
                <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    type="submit"
                    sx={{ borderRadius: 10 }}
                    style={{ margin: '1rem', marginRight: '5%' }}
                    onClick={handleSubmit(onSubmitWrapper)}
                >
                    Sign up now
                </Button>
            </Box>
        </Box>
    )
}