import React, { useEffect } from 'react';
import CatchAllRoute from 'src/components/CatchAllRoute';
import Stack from '@mui/material/Stack';
import { useMediaQueries } from '../../hooks/useMediaQueries';

export default function Content() {
  const { matchesMedium } = useMediaQueries();
  useEffect(() => {  }, [matchesMedium])
  return (
    <Stack justifyContent="center" marginLeft={{xs: '10vw', md: '25vw' }} marginBottom={{xs: matchesMedium ? '30vh' : '0vh'}} width={{xs: '80vw', md: '50vw'}}>
      <CatchAllRoute />
    </Stack>
  );
}