import React, { MouseEventHandler } from "react";
import { useNavigate } from "react-router-dom";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import { ContractorInfo } from "src/types";

type ContractorCardProps = {
    contractor: ContractorInfo,
    vertical?: string
}

export default function ContractorCard({contractor, vertical}: ContractorCardProps){
    const navigate = useNavigate();
    const contractorProfileImage = contractor.photos.find(photo => Boolean(photo.is_profile))
    
    return (
        <Card elevation={0} sx={{ marginBottom: '1.5rem' }}>
            <CardMedia
                title='foo'
                component="img"
                image={contractorProfileImage?.url || `${process.env.PUBLIC_URL}/images/builders_icon.svg`}
                onClick={() => navigate(`/contractors/${contractor.id}`)}
                sx={{
                    background: '#332966',
                    fill: '#FBB03C',
                    minHeight: '180px',
                    maxHeight: '180px', 
                    borderRadius: '15px',
                    cursor: "pointer"
                }}
            />
            <CardContent>
                <Typography gutterBottom sx={{ fontSize: '16px', fontWeight: 'bold'}} component="div">
                    {contractor.company_name}
                </Typography>
                <Typography gutterBottom variant="body2" component="div" noWrap>
                    <RoomOutlinedIcon/> Distance: {contractor.distance} {contractor.units}
                </Typography>
                <Typography gutterBottom variant="body2" component="div" noWrap>
                    <InventoryOutlinedIcon/> Permits: {vertical ? contractor.permits.filter(permit => permit.vertical == vertical).length : contractor.permits.length}
                </Typography>
            </CardContent>
        </Card>
    );
}