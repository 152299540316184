import React from "react";
import { Outlet } from "react-router-dom";
import MainNavBar from "../MainNavBar";

export default function Layout() {
    return (
      <>
        <MainNavBar />
        {/* An <Outlet> renders whatever child route is currently active,
            so you can think about this <Outlet> as a placeholder for
            the child routes we defined above. */}
        <Outlet />
      </>
    );
  }