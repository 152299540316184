import React, {useState, useEffect} from 'react'
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from "@mui/material";

export const useMediaQueries = () => {
  const theme = useTheme();
  const matchesExtraLarge = useMediaQuery(theme.breakpoints.down('xl'));
  const matchesLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const matchesMedium = useMediaQuery(theme.breakpoints.down('md'));
  const matchesSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const [mediaQueries, setMediaQueries] = useState({matchesExtraLarge, matchesLarge, matchesMedium, matchesSmall, matchesExtraSmall});

    useEffect(() => {
      setMediaQueries({matchesExtraLarge, matchesLarge, matchesMedium, matchesSmall, matchesExtraSmall});
    },[matchesExtraLarge, matchesLarge, matchesMedium, matchesSmall, matchesExtraSmall]);
    return mediaQueries;
}