import React from 'react';
import { Grid } from "@mui/material";
import { getContractorsForStateCity } from 'src/lib/apiClient';
import { useParams } from 'react-router-dom';
import CityContractorView from '../../components/CityContractorView';
import Content from '../Content';
import { useQuery } from '@tanstack/react-query';
import { QueryStatus } from 'src/types';
import Loading from 'src/components/Loading';

const QUERY_KEY_CITY_CONTRACTORS = 'cityContractors'
export default function Cities() {

  // state
  const { stateAbbreviation, cityName } = useParams();
  const { status, data: contractors, error } = useQuery({
    queryKey: [QUERY_KEY_CITY_CONTRACTORS, stateAbbreviation, cityName],
    queryFn: () => getContractorsForStateCity(stateAbbreviation, cityName),
  })

  return (
    <Grid container>
      <Grid item xs={12} lg={12}>
        <Content />
      </Grid>
      <Grid item xs={12} lg={12}>
        {
          status === QueryStatus.PENDING ? (
            <Loading size={100} position='relative'/>
          ) : status === QueryStatus.ERROR ? (
            error.message
          ) : (
          <Grid container xs={12} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ marginBottom: "4vw" }} >
            <CityContractorView contractors={contractors} />
          </Grid>
          )
        }
      </Grid>
    </Grid>
    
  );
}