import { Grid,} from "@mui/material";
import { ContractorInfo } from "src/types";
import ContractorCard from "../ContractorCard";

type ContractorViewProps = {
    contractors: ContractorInfo[]
    vertical?: string
}

export default function ContractorView({contractors, vertical}: ContractorViewProps) {
    
    return (
        <Grid xs={12} md={10} container margin={{ xs: '0 0 0 0', md: '0 8vw 0 8vw' }} spacing={2}>
            {contractors.map((contractor, index) => {
                
                return (
                    <Grid item lg={3} xs={6} key={`contractor-detail-${index}`} marginLeft={'-5px'}>
                        <ContractorCard contractor={contractor} vertical={vertical} />
                    </Grid>
                )
                
            })}
        </Grid>
    )
}