import { City, CitiesByState} from "src/types";

export const formatCities = (citiesData: City[]): CitiesByState => {
    const citiesGroupedByState = citiesData.reduce((acc, currentCity) => {
        const { state } = currentCity;

        if(!acc[state]){
            acc[state] = []
        }

        acc[state].push(currentCity)
        return acc
    }, {} as CitiesByState)

    return citiesGroupedByState
}