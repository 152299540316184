import React, { useState, useEffect } from "react";
import { ContractorInfo, ContractorPermit, ContractorsByCategory } from "src/types";
import categories from '../../assets/data/categoriesAll.json'
import {useMediaQueries} from '../../hooks/useMediaQueries'
import { formatCityContractors } from "src/utils/formatCityContractors";
import ContractorCard from '../ContractorCard'
import CategoryCard from '../CategoryCard'
import { Grid, Typography } from "@mui/material";

export default function CityContractorView({contractors}: {contractors: ContractorInfo[]}) {

    const {matchesMedium} = useMediaQueries();
    const [cityContractors, setCityContractors] = useState({} as ContractorsByCategory)

    useEffect(() => {
        const contractorsByCategory: ContractorsByCategory = formatCityContractors(contractors)
        setCityContractors(contractorsByCategory)
    }, [contractors])

    useEffect(() => { }, [matchesMedium])

    return (
        <Grid container margin={ '0 8vw 0 8vw' } spacing={2}>
            {Object.entries(cityContractors).map(([currentCategory, categoryContractors]: [string, ContractorInfo[]], index) => {
                const currentCategoryInfo = categories.find(category => category.title === currentCategory)
                
                return (
                    <Grid item lg={12} key={`contractor-categories-${index}`}>
                        <Grid container spacing={3} marginBottom={'3vh'} alignItems={'center'} >
                            <Grid item lg={1.5} xs={4} marginRight={'1rem'}>
                                {currentCategoryInfo && <CategoryCard title={''} picture={currentCategoryInfo?.picture}/>}
                            </Grid>
                            <Grid item lg={9} xs={7} >
                                <Typography variant="h4"> {currentCategory} {!matchesMedium && ` - ${categoryContractors.length} Contractors available.`}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} >
                            {categoryContractors.sort(({permits:aPermits, distance:aDistance}: ContractorInfo, {permits:bPermits, distance:bDistance}: ContractorInfo) => {
                                const aPermitsForVertical = aPermits.filter((permit: ContractorPermit) => permit.vertical === currentCategory)
                                const bPermitsForVertical = bPermits.filter((permit: ContractorPermit) => permit.vertical === currentCategory)
                                return bPermitsForVertical.length - aPermitsForVertical.length || aDistance - bDistance 
                            })
                            .slice(0,4)
                            .map((contractor, index) => {
                                return (
                                    <Grid item lg={3} md={6} xs={12} key={`contractor-detail-${index}`}>
                                        <ContractorCard contractor={contractor} vertical={currentCategory}/>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
                   
                )
            })}
         </Grid>

            
    );
}