import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getSupportedCities } from 'src/lib/apiClient';
import { formatCities } from 'src/utils/formatCities';
import { Typography, Grid } from '@mui/material';
import { City, QueryStatus } from 'src/types';
import Loading from 'src/components/Loading';
import { useQuery } from '@tanstack/react-query';

const QUERY_KEY_CITIES = 'cities'

export default function Cities() {
  const navigate = useNavigate();

  const { status, data: citiesTest, error } = useQuery({
    queryKey: [QUERY_KEY_CITIES],
    queryFn: async () => {
      const cities = await getSupportedCities();
      const citiesByState = formatCities(cities)
      return citiesByState
    },
  })
  
  return (
    <Grid container spacing={2} padding={{ xs: '1.5rem', md: '4rem'}}>
      <Grid item xs={12} marginBottom={'1.5rem'}>
        <Grid>
          <img
            src={`${process.env.PUBLIC_URL}/images/hero_cityselect.jpg`}
            alt="the Monty Homes stylized electrified home" style={{
              width: "100%",  borderRadius: '1rem'
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignContent={'center'} justifyContent={'center'}>
        
        {
          status === QueryStatus.PENDING ? (
            <Loading size={100} position='relative'/>
          ) : status === QueryStatus.ERROR ? (
            error.message
          ) : (
            Object.entries(citiesTest).map((stateData: [string, City[]]) => {
              const [state, stateCities] = stateData
              return (
                <Grid item lg={2} key={`state-cities-${state}`}>
                  <Typography variant="h4" sx={{ color: 'grey' }} gutterBottom>{state}</Typography>
                  {
                    stateCities.map(city => {
                      const { state_abbreviation, name } = city
                      return (
                        <Typography sx={{ cursor: "pointer" }}onClick={() => navigate(`/cities/${state_abbreviation}/${name}`)} variant="h6" key={`state-city-${name}`}>{name}</Typography>
                      )
                    })
                  }
                </Grid>
              )
            })
          )
        }
        
      </Grid>
    </Grid>
    
  );
}