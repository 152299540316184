import Axios from 'axios';

import { API_URL, API_TOKEN } from '../config';

export const axios = Axios.create({
  baseURL: API_URL,
  // withCredentials: false,
});

axios.interceptors.request.use(function (config) {
  // Do something before request is sent

  config.headers.Accept =  'application/json'
  config.headers.Authorization = `Bearer ${API_TOKEN}`

  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// axios.interceptors.response.use(
//   (response) => {
//     return response.data;
//   },
//   (error) => {
//     // const message = error.response?.data?.message || error.message;
//     // useNotificationStore.getState().addNotification({
//     //   type: 'error',
//     //   title: 'Error',
//     //   message,
//     // });

//     return Promise.reject(error);
//   }
// );