import {axios} from './axios'
import {API_URL} from '../config'
import { ContractorInfo, Location, City } from 'src/types'

const CONTRACTORS_URL = `${API_URL}/contractors`
const SIGNUP_URL = `${API_URL}/signup`
const QUOTE_URL = `${API_URL}/quote`
const CLAIM_URL = `${API_URL}/claim`
const ZIPCODE_URL = `${API_URL}/zipcodes`
const CITIES_URL = `${API_URL}/cities`

// helps with testing
function wait(milliseconds: number){
    return new Promise(resolve => {
        setTimeout(resolve, milliseconds);
    });
}

export const getContractorsForStateAndVertical = async (zipcode: string, vertical?: string) => {
    // TODO: validate state abbreviation
    // TODO: validate vertical

    const params = {
        zipcode,
        vertical
    }
    const {data} = await axios.get(CONTRACTORS_URL, {
        params
    })

    return data.data
}

export const getContractorsForStateCity = async (stateAbbreviation: string|undefined, cityName: string|undefined) => {
    // TODO: validate state abbreviation
    // TODO: validate vertical
    const cities = await getSupportedCities()
    const city = cities.find(city => city.state_abbreviation === stateAbbreviation && city.name === cityName)
    
    if(!city){
        const errorMessage = `City ${cityName} not found in state ${stateAbbreviation}`
        console.error(errorMessage)
        return []
        // throw new Error(errorMessage)
    }

    const {lat, lng} = city
    const location = `${lat},${lng}`
    const params = {
        location
    }
    const {data} = await axios.get(CONTRACTORS_URL, {
        params
    })

    return data.data
}



export const getContractor = async (contractorId: string | undefined): Promise<any> => {
    
    // TODO: validate Contractor Id
    if(!contractorId){
        return {} as ContractorInfo
    }

    const CONTRACTOR_URL = `${CONTRACTORS_URL}/${contractorId}`

    const {data: responseData} = await axios.get(CONTRACTOR_URL)
    const {data} = responseData
    const contractor = data[0]
    return contractor
}

export const getSupportedZipcodes = async (): Promise<Location[]> => {
    const {data: responseData} = await axios.get(ZIPCODE_URL)
    const {data} = responseData
    return data
}

export const getSupportedCities = async (): Promise<City[]> => {
    
    const {data: responseData} = await axios.get(CITIES_URL)
    const {data} = responseData
    return data
}

// POST SIGNUP
export const postSignup = async (email: string, vertical: string) => {
    const response = await axios.post(SIGNUP_URL, {email, vertical})
    return response
}

// POST CONTRACTOR QUOTE
export const postContractorQuote = async(firstName: string, lastName: string, address: string, email: string, phone: string, contractorInfo: ContractorInfo) => {
    const response = await axios.post(QUOTE_URL, {firstName, lastName, address, email, phone, contractor: contractorInfo.company_name, contractorState: contractorInfo.state, contractorEmail: contractorInfo.email})
    return response
}

// POST CONTRACTOR SIGNUP
export const postContractorSignup = async(name: string, businessName: string, email: string) => {
    const response = await axios.post(CLAIM_URL, {name, businessName, email})
    return response
}
