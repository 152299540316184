export type State = {
    isActive: boolean
    name: string
    territoryAbbreviation: string
}

export type Category = {
    picture: string
    title: string
    slug: string
}

export type ContractorQuote = {
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
    address: string
    message: string
  }

export type ContractorSignup = {
    name: string
    businessName: string
    email: string
}

export type Contractor = {
    address: string
    bio: string
    categories: string[]
    email: string
    financing: boolean
    fullAddress: string
    name: string
    picture: string
    slug: string
}

export type ContractorPhoto = {
    id: string
    contractor_id: string
    state: string
    contractor_name: string
    url: string
    is_profile: string
}

export type ContractorPermit = {
    id: string
    city: string
    description: string
    duration: string
    fees: string
    file_date: string
    final_date: string
    inspections_pass_rate: string
    issue_date: string
    job_value: string
    juridiction: string
    lat: string
    lng: string
    address: string
    permit_number: string
    state: string
    status: string
    street: string
    street_no: string
    permit_type: string
    permit_subtype: string
    tags: string
    zip_code: string
    contractor_id: string
    vertical: string
}

export type ContractorInfo = {
    id: string
    company_name: string
    phone_number:string
    email:string
    website:string
    address:string
    state:string
    category:string[]
    description:string
    reviews:any
    photos: ContractorPhoto[]
    permits: ContractorPermit[]
    active_regions?:string
    lat?:string
    lng?:string
    owner_id?: string
    distance: number
    units?: string
}

export type Location = {
    zip: string
    lat: string
    lng: string
    city: string
    state_id: string
    state_name: string
}

export type City = {
    name: string
    state: string
    state_abbreviation: string
    county: string
    lat: string
    lng: string
    zipcodes: string
}

export type CitiesByState = {
    [state: string]: City[]
}

export type ContractorsByCategory = {
    [category: string]: ContractorInfo[]
}

export enum QueryStatus {
    PENDING = 'pending',
    SUCCESS = 'success',
    ERROR = 'error'
}