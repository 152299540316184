import React, { useContext } from "react";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { useNavigate } from "react-router-dom";

import { SelectedLocationContext } from '../../context/selectedLocation'
import LocationSelect from "../LocationSelect";
import MenuButton from "./MenuButton";
import LocationSelectLabel from "../LocationSelectLabel";
import Stack from "@mui/material/Stack";
import { grey } from "@mui/material/colors";

const navbarButtonStyling = {
  color: 'grey',
  margin: '1.1rem 0.5rem 1.1rem ',
  // backgroundColor: 'transparent'
  "&:hover": { backgroundColor: "transparent" }
}

export default function MainNavBar() {
  // context
  const {
    selectedLocation,
    handleSelectedLocationChange
  } = useContext(SelectedLocationContext);

  const navigate = useNavigate();

  return (
    <>
      <AppBar position="sticky" component="nav" sx={{ bgcolor: "inherit", boxShadow: 0, display: { md: 'block', xs: 'none' } }}>
        <Toolbar >
          <img style={{ cursor: "pointer" }} onClick={() => navigate('/')} src={`${process.env.PUBLIC_URL}/images/main_logo.png`} alt="Logo" height='40px' width='240px' />
          <Box sx={{ flexGrow: 1 }} />

          <Stack direction="row" alignItems="center">
            <LocationSelectLabel label="CURRENT ZIP CODE:" sx={() => ({ 
              color: grey[400], marginRight: "0.5rem", fontSize: ".875rem"
            })}></LocationSelectLabel>
            <LocationSelect value={selectedLocation} onChange={handleSelectedLocationChange} id="nav-zip-input"/>
          </Stack>

          <Divider orientation="vertical" flexItem variant="middle" sx={{ marginLeft: '1.1rem' }} />
          <Button variant="text" onClick={() => navigate('/about')} sx={navbarButtonStyling}>
            About Us
          </Button>
          <Button color="inherit" disabled onClick={() => navigate('/cities')} sx={{ ...navbarButtonStyling, borderRadius: '15px' }}>
            Cities
          </Button>

          <Button variant="outlined" color="inherit" onClick={() => navigate('/contractor-sign-up')} sx={{ ...navbarButtonStyling, borderRadius: '15px' }}>
            Contractor Sign Up
          </Button>
        </Toolbar>
      </AppBar>


      <AppBar position="sticky" component="nav" sx={{ paddingTop: 1, bgcolor: "inherit", boxShadow: 0, display: { md: 'none', xs: 'block' } }}>
        <Toolbar sx={{ padding: '0 0'}}>
          <MenuButton></MenuButton>
          <Box  />
          <img style={{ cursor: "pointer", padding: '0 0' }} onClick={() => navigate('/')} src={`${process.env.PUBLIC_URL}/images/main_logo.png`} alt="Logo" height='40px'/>
        </Toolbar>
      </AppBar>
    </>
  )
}