import React from "react";
import { Routes, Route, Link } from "react-router-dom";
import Layout from "../components/Layout";
import Main from "../pages/Main";
import About from "../pages/About";
import Marin from "../pages/Marin";
import ContractorProfile from "../pages/ContractorProfile";
import Contractors from "../pages/ContractorSignUp";
import Cities from "../pages/Cities";
import Learn from "../pages/Learn";
import City from "../pages/City";

export default function MyRouter() {
    return (
        
        <Routes>
            {/* Routes nest inside one another. Nested route paths build upon
                  parent route paths, and nested route elements render inside
                  parent route elements. See the note about <Outlet> below. */}
          <Route path="/" element={<Layout />}>
            <Route index element={<Main />} />
            <Route path="about" element={<About />} />
            <Route path="marin" element={<Marin />} />
            <Route path="contractors/:slug" element={<ContractorProfile />} />
            <Route path="contractor-sign-up" element={<Contractors />} />
            <Route path="cities" element={<Cities />} />
            <Route path="cities/:stateAbbreviation/:cityName" element={<City />} />
            <Route path="learn/:slug" element={<Learn />} />
            
  
            {/* Using path="*"" means "match anything", so this route
                  acts like a catch-all for URLs that we don't have explicit
                  routes for. */}
            <Route path="*" element={<NoMatch />} />
          </Route>
        </Routes> 
        
    );
  }

  
  function NoMatch() {
    return (
      <div>
        <h2>Nothing to see here!</h2>
        <p>
          <Link to="/">Go to the home page</Link>
        </p>
      </div>
    );
  }