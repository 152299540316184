import * as React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { ContractorQuote } from "../../types";
import { useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useMediaQueries } from "src/hooks/useMediaQueries";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().required("Email is required"),
  phoneNumber: Yup.string().optional(),
  address: Yup.string().required("Address is required"),
  message: Yup.string().optional(),
});

const contractorQuoteDefaultValues = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  address: "",
  message: "",
};

export default function ContractorQuoteForm({
  title,
  onSubmit,
}: {
  title: string;
  onSubmit: any;
}) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ContractorQuote>({
    resolver: yupResolver(validationSchema),
    defaultValues: contractorQuoteDefaultValues,
  });

  const onSubmitWrapper = async (data: any) => {
    await onSubmit(data);
    // TODO: handle failures
    reset(contractorQuoteDefaultValues);
  };

  const {matchesSmall} = useMediaQueries();

  const [showForm, setShowForm] = useState(false);

  const handleFormSubmit = async () => {
    handleSubmit(onSubmitWrapper)();
    toggleForm();
  }

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  return (
    <>
      {/* Desktop Sticky Form */}
      {(showForm || !matchesSmall) && (
        <Box
          px={3}
          py={2}
          className="contact-form"
          bgcolor="white"
          style={{
            border: "1px solid #707070",
            borderRadius: "8px",
            height: "auto",
            paddingTop: "20px !important",
          }}
          sx={{
            display: { md: "block" },
            bottom: { xs: "3vh", md: "0" },
            left: { xs: "5vw", md: "auto"},
            position: { xs: "absolute", sm: "relative" },
            width: {
              xs: "90vw",
              md: "45vw"
            },
            overflow: 'auto'
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Grid item xs={10} md={10}>
                <Typography
                  variant="h4"
                  component="div"
                  sx={{
                    flexGrow: 1,
                    marginBottom: "1rem",
                    marginLeft: "1rem",
                    fontSize: { xs: "1rem", md: "1.5rem" },
                  }}
                >
                  {title}
                </Typography>
              </Grid>
              <Grid item xs={2} md={4}>
                <IconButton
                  onClick={() => {
                    toggleForm();
                  }}
                  sx={{ position: "absolute", right: "10px", top: "10px" }}
                >
                  <HighlightOffIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                id="firstName"
                label="First Name"
                fullWidth
                margin="dense"
                {...register("firstName")}
                error={errors.firstName ? true : false}
                sx={{
                  marginTop: "-0.1px",
                }}
              />
              <Typography variant="inherit" color="textSecondary">
                {errors.firstName?.message}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                id="lastName"
                label="Last Name"
                fullWidth
                margin="dense"
                {...register("lastName")}
                error={errors.lastName ? true : false}
                sx={{
                  marginTop: "-0.1px",
                }}
              />
              <Typography variant="inherit" color="textSecondary">
                {errors.lastName?.message}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                id="email"
                label="Your Email"
                fullWidth
                margin="dense"
                {...register("email")}
                error={errors.email ? true : false}
                sx={{
                  marginTop: "-0.1px",
                }}
              />
              <Typography variant="inherit" color="textSecondary">
                {errors.email?.message}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                id="phoneNumber"
                label="Phone Number"
                fullWidth
                margin="dense"
                {...register("phoneNumber")}
                error={errors.phoneNumber ? true : false}
                sx={{
                  marginTop: "-0.1px",
                }}
              />
              <Typography variant="inherit" color="textSecondary">
                {errors.phoneNumber?.message}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                size="small"
                id="address"
                label="Street Address"
                fullWidth
                margin="dense"
                {...register("address")}
                error={errors.address ? true : false}
                sx={{
                  marginTop: "-0.1px",
                }}
              />
              <Typography variant="inherit" color="textSecondary">
                {errors.address?.message}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                id="message"
                label="Message"
                fullWidth
                margin="dense"
                multiline
                minRows={3}
                maxRows={4}
                {...register("message")}
                error={errors.message ? true : false}
                sx={{
                  marginTop: "-0.1px",
                }}
              />
              <Typography variant="inherit" color="textSecondary">
                {errors.message?.message}
              </Typography>
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
            <Button
              color="primary"
              variant="outlined"
              size="small"
              type="submit"
              sx={{ borderRadius: 5 }}
              style={{ margin: "1rem", marginRight: "5%" }}
              onClick={() => handleFormSubmit()}
            >
              Contact
            </Button>
          </Box>
        </Box>
      )}

      {/* Mobile Sticky Footer */}
      {matchesSmall && !showForm && (
        <Box
          bgcolor="white"
          // position="absolute"
          bottom="3vh"
          style={{
            border: "1px solid #707070",
            borderRadius: "12px",
            width: "85vw",
            height: "auto",
            display: "flex",
          }}
        >
          <>
            <Typography variant="h4" sx={{ margin: ".5rem .5rem" }}>
              Contact
            </Typography>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "grey",
                display: {
                  xs: "block",
                  md: "none",
                  marginLeft: "auto",
                  borderRadius: "10px",
                },
              }}
              onClick={() => {
                toggleForm();
              }}
            >
              Click Here
            </Button>
          </>
        </Box>
      )}
    </>
  );
}
