import { createContext } from 'react';
import { Location } from 'src/types';

const defaultLocation = {
    selectedLocation: {
        zip: '94965',
        lat: '37.85263',
        lng: '-122.52699',
        city: 'Sausalito',
        state_id: 'CA',
        state_name: 'California'
    },
    handleSelectedLocationChange: (location: Location) => {}
}
export const SelectedLocationContext = createContext(defaultLocation);