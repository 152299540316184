import React, { MouseEventHandler } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

type CategoryCardProps = {
    title: string
    picture: string
    onClick?: MouseEventHandler
}

export default function CategoryCard({title, picture, onClick}: CategoryCardProps){
    const maxSize = 120;
    const onHoverCursor = onClick ? 'pointer' : 'auto'
    return (
        <Card elevation={0} sx={{ width: maxSize * 1.1, maxWidth: maxSize * 1.1, maxHeight: maxSize, cursor: onHoverCursor }} onClick={onClick}>
            <CardMedia
                height={`${maxSize * 0.8}`}
                width={`${maxSize * 0.8}`}
                image={`${process.env.PUBLIC_URL}/images/${picture}`}
                title={`${title}`}
                component="img"
            />
            <CardContent sx={{padding: '0px'}}>
                <Typography gutterBottom sx={{fontSize: '14px', textAlign: 'center'}}>
                    {title}
                </Typography>
            </CardContent>
        </Card>
    );
}