import Grid from '@mui/material/Grid';
import React from 'react';
import Content from '../Content';

export default function MarinContent() {
  return (
    <Grid xs={10} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ marginTop: "1rem", marginBottom: "4rem" }} spacing={8}>
      <Content />
    </Grid>
  );
}