import React, { useState } from 'react';
import './App.css';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import Routes from './routes';
import { Location } from './types';
import {SelectedLocationContext} from './context/selectedLocation'
import UnderConstruction from './pages/UnderConstruction';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// const Background = styled(Paper)(({ theme }) => ({
//   color: theme.palette.text.secondary,
// }));

const queryClient = new QueryClient()

function App() {
  // state
  const [isLightMode] = useState(true)
  const [selectedLocation, setSelectedLocation] = useState({
    zip: '94965',
    lat: '37.85263',
    lng: '-122.52699',
    city: 'Sausalito',
    state_id: 'CA',
    state_name: 'California'
});
  // hooks

  // handlers
  const handleSelectedLocationChange = (location: Location) => {
    setSelectedLocation(location)
  }

  // context
  const defaultSelectedLocationContext = {
    selectedLocation,
    handleSelectedLocationChange
  }

  // const toggleLightMode = () => {
  //   setIsLightMode(useMode => !useMode)
  // }

  const themeMode = isLightMode ? 'light':'dark';
  /* 
    TODO: update theme to include breakpoints, fonts, etc.

    breakpoints: {
      values: {
        laptop: 1024,
        tablet: 640,
        mobile: 0,
        desktop: 1280,
      },
    },
  */
  let theme = createTheme({ 
    palette: { mode: themeMode }
  });
  theme = responsiveFontSizes(theme);


  return (
    <>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <Grid container sx={{overflow: "auto"}}>
          <ThemeProvider theme={theme}>
          <SelectedLocationContext.Provider value={defaultSelectedLocationContext}>
          <Box width={'100vw'} height={'100vh'}>
            <Paper key={0} elevation={0}>
              {/* <UnderConstruction /> */}
              <Routes />
            </Paper>
          </Box>
          </SelectedLocationContext.Provider>
          </ThemeProvider>
        </Grid>
      </QueryClientProvider>
    </>
  );
}

export default App;
