import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {Button} from '@mui/material';

export default function BackButton() {
    const navigate = useNavigate();
    return (
      <Button onClick={() => navigate(-1)} sx={{color: 'grey' }}>
        <ArrowBackIosIcon /> Back
      </Button>
    );
  };