import * as React from 'react';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export enum Position {
    ABSOLUTE = 'absolute',
    FIXED = 'fixed',
    RELATIVE = 'relative',

}

type LoadingProps = {
    size?: number
    thickness?: number
    position?: string
}

const DEFAULT_SIZE = 100
// const DEFAULT_THICKNESS = 2
const DEFAULT_COLOR_LIGHT = '#93568A'
const DEFAULT_COLOR_DARK = '#ADC1C1'

const DEFAULT_STYLE_ABSOLUTE = {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}

const DEFAULT_STYLE_RELATIVE = {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}

export default function Loading(
    props: CircularProgressProps & LoadingProps,
  ) {

    const { position = Position.ABSOLUTE } = props

    let style = {}

    if(position === Position.ABSOLUTE) {
        style = {...DEFAULT_STYLE_ABSOLUTE}
    }

    if(position === Position.RELATIVE) {
        style = {...DEFAULT_STYLE_RELATIVE}
    }

    return (
      <Box sx={style}
        >
        <CircularProgress size={props.size || DEFAULT_SIZE} sx={{color: (theme) => (theme.palette.mode === 'light' ? DEFAULT_COLOR_LIGHT : DEFAULT_COLOR_DARK)}} />
        <Box
          sx={{...DEFAULT_STYLE_ABSOLUTE}}
        >
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
          >{`Loading`}</Typography>
        </Box>
      </Box>
    );
  }
