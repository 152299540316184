import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import CategoryCardGroup from 'src/components/CategoryCardGroup';
import LocationSelect from "src/components/LocationSelect";
import { SelectedLocationContext } from "src/context/selectedLocation";
import { useContext } from "react";
import SwipeableTextMobileStepper from "src/components/SwipeableTextMobileStepper";
import LocationSelectLabel from "src/components/LocationSelectLabel";
import grey from "@mui/material/colors/grey";

const navbarButtonStyling = {
  color: 'grey',
  margin: '.1rem 0.5rem 0.5rem ',
  "&:hover": { backgroundColor: "transparent" }
}

export default function Main() {

  const {
    selectedLocation,
    handleSelectedLocationChange
  } = useContext(SelectedLocationContext);

  // hooks
  const navigate = useNavigate()

  const learnMoreButtonStyling = {
    color: 'white!important',
    borderRadius: '15px',
    margin: '15px',
    background: 'grey',
    "&:hover": {
      // don't alter the color on hover
      background: "grey"
    }
  }

  const titleTextStyling = {
    flexGrow: 1,
    marginBottom: '2rem',
    fontFamily: 'serif',
    fontWeight: '300',
    marginLeft: { xs: 0, sm: 0 }
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={3} marginBottom={4}>
        <Grid xs={12} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Stack direction="row" alignItems="center" justifyContent="center" spacing={{ xs: 0, sm: 4 }} marginTop="1rem"  sx={{ flexDirection: { xs: "column", md: "row" } }}>

            <Grid xs={12} sm={10} md={5} spacing={2} justifyContent="center" rowSpacing={4} order={{ xs: 2, sm: 2, md: 1, lg: 1 }} px={{ sm: 0, md: 2 }}>

              <Typography variant="h3" component="h2" sx={titleTextStyling}>
                Find qualified contractors to install, <SwipeableTextMobileStepper textStyling={{...titleTextStyling, marginBottom: '0rem', fontWeight: "bold"}}/>
              </Typography>
              
              <Typography variant="body1" component="div" gutterBottom sx={{ flexGrow: 1, fontSize: { xs: '1.0rem', sm: '1.2rem' }}}>
                From heat pumps to sustainable landscape design, we've got you covered. Monty Homes walks you
                through the steps and connects you with the right contractors in your area to complete the jobs that
                lower your cost and work for the climate.
              </Typography>
            </Grid>
            <Grid xs={10} sm={5} order={{ xs: 1, sm: 1, md: 2, lg: 2 }}>
              <img
                src={`${process.env.PUBLIC_URL}/images/house_home_hero.png`}
                alt="the Monty Homes stylized electrified home" style={{
                  width: "100%", height: "100%", borderRadius: '3%',
                }}
              />
            </Grid>

          </Stack>
        </Grid>
        
        <Grid xs={12} sm={10} columnSpacing={{ xs: 1, sm: 2, md: 3 }} justifyContent="center" margin="auto" px={{xs: 3, sm: 1}}>
            <div style={{paddingTop: "0.5rem"}}>
              <Stack direction="row" alignItems="center">
                <LocationSelectLabel label="Enter Zip Code:" sx={() => ({ 
                  color: grey[900], fontFamily: 'serif', marginRight: "0.5rem", fontSize: {xs: "1.2rem", md: "1.8rem"}
                })}></LocationSelectLabel>
                <LocationSelect value={selectedLocation} onChange={handleSelectedLocationChange} id="home-page-zip-input" />
              </Stack>
            </div>
          <CategoryCardGroup urlPath='learn' headerText="Choose A Service Below" />
        </Grid>
      </Grid>
    </Box>
  );
}