import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';

type Anchor = 'left' ;

const menuItemToNav = [
    {
        name: 'About Us',
        link: '/about'
    },
    {
        name: 'Cities',
        link: '/cities'
    },
    {
        name: 'Contractor Sign Up',
        link: '/contractor-sign-up'
    }
]

export default function MenuButton() {
  const [state, setState] = React.useState({
    left: false
  });

  const navigate = useNavigate();

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}

    >
      <List>
        {menuItemToNav.map(({name, link}, index) => (
          <ListItem key={name} disablePadding>
            <ListItemButton onClick={() => navigate(link)}>
              {/* <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon> */}
              <ListItemText primary={name}/>
            </ListItemButton>
          </ListItem>
        ))}
        <ListItemButton href='https://carbon-free-neighborhood.circle.so/home' target={'_blank'}>
            <ListItemText primary={'Community Chat Board'}/>
        </ListItemButton>
      </List>
    </Box>
  );

  return (
    <div >
      {(['left'] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}><MenuIcon sx={{ color: 'black' }}/></Button>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}