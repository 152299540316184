import React from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Grid } from "@mui/material"

import Stack from "@mui/material/Stack";
import CategoryCard from "../CategoryCard";

import categories from '../../assets/data/categoriesAll.json'

type CategoryCardGroupProps = {
    urlPath: string,
    headerText?: string
}

export default function CategoryCardGroup({ urlPath, headerText }: CategoryCardGroupProps) {
    // hooks
    const navigate = useNavigate();
    // helpers
    const handleCardNavigate = (slug: String) => navigate(`/${urlPath}/${slug}`)

    return (
        <>
        <Typography variant="h4" sx={{ fontFamily: 'serif' }} marginBottom={{sm: 2}}>{headerText}</Typography>
        <Grid marginTop={0} sx={{ overflowX: 'scroll' }} display={'flex'}>
            
            <Stack direction="row" spacing={1}>

                {
                    categories.map(({ title, picture, slug }) => {
                        return (
                            <CategoryCard key={title} title={title} picture={picture} onClick={() => handleCardNavigate(slug)} />
                        )
                    })
                }
            </Stack>
        </Grid>
        </>
    );
}