import { FormLabel, SxProps, Theme } from '@mui/material';

interface LocationSelectLabelProps {
    label: string;
    sx?: SxProps<Theme>;
  }

export default function LocationSelectLabel({ label, sx }: LocationSelectLabelProps) {
    return (
        <FormLabel sx={[ ...(Array.isArray(sx) ? sx : [sx]) ]}>
          {label}
        </FormLabel>
      );

}