import React, {useState} from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Stack, Typography, Alert, Link, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {CFH_CALENDLY_LINK} from '../../config'
import ContractorSignupForm from 'src/components/ContractorSignupForm';
import { ContractorSignup } from 'src/types';
import PositionedSnackbar from 'src/shared/PositionedSnackbar';
import { postContractorSignup } from 'src/lib/apiClient';

const alertBackgroundColor = '#F5CECE'

const MEMBERSHIP_BENEFITS = [
  'Customized profile on our marketplace',
  'Access to our homeowner bids platform',
  'Unlimited leads',
  'Job board posting',
  'Lead referrals (make money off your excess leads)',
  'Invite only industry events and meetups',
  'Cancel any time'
]


export default function ContractorSignUp() {
    const [success, setSuccess] = useState(false);
    const onSubmit = async (formData: ContractorSignup) => {
        // console.log(formData)
        const { name, businessName, email } = formData
        const response = await postContractorSignup(name, businessName, email)
        if (response.status === 200) {
            console.log('setting success')
            setSuccess(true)
            const timer = setTimeout(() => {
            setSuccess(false)
            }, 5000);
            return () => clearTimeout(timer);
        }
    }

  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={12}>
        <Alert  icon={false} sx={{backgroundColor: alertBackgroundColor, justifyContent: "flex-end"}}>
          
          <Link
            variant="body2"
            href={CFH_CALENDLY_LINK}
            underline="none"
            target="_blank"
            rel="noopener"
            sx={{alignItems: 'center', alignContent: 'center', color: 'grey'}}
          >
            <Typography sx={{ display: "flex", alignItems: "center" }}>
              <strong>Schedule a call!</strong><OpenInNewIcon sx={{fontSize: '1em', marginLeft: '0.2rem',}} /> 
            </Typography>
          </Link>
        </Alert>
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={4} marginTop="2rem" marginBottom="1rem">

          <Grid xs={5} spacing={2} justifyContent="center" rowSpacing={4}>

            <Typography variant="h3" component="div" gutterBottom >
            Join the contractor network that helps you grow your business while doing the right thing for the planet
            </Typography>

            <Typography variant="body1" component="div" gutterBottom sx={{ flexGrow: 1, marginBottom: '2rem', fontSize: 22 }}>
              Access a suite of tools as well as a network that supports your growing business.
            </Typography>
            
          </Grid>
          <Grid xs={5}>
            <img
              src={`${process.env.PUBLIC_URL}/images/coop_signup_hero.jpg`}
              alt="a contractor in a hard hat installs a heat pump on a ladder against a paneled home." style={{
                width: "100%", height: "100%", borderRadius: '3%',
              }}
            />
          </Grid>

          </Stack>
        </Grid>
        <Grid xs={12}>
          <Stack direction="row" alignItems="center" justifyContent="center" spacing={4} marginTop="1rem" marginBottom="5rem">
            <Grid xs={5}>
              <img
                src={`${process.env.PUBLIC_URL}/images/coop_signup_feature_section.png`}
                alt="multiple rounded photos of various electric home appliances." style={{
                  width: "100%", height: "100%", borderRadius: '3%',
                }}
              />
            </Grid>
            <Grid xs={5} spacing={2} justifyContent="center" rowSpacing={4}>

              <Typography variant="h3" component="div" gutterBottom >
                Co-op membership includes:
              </Typography>
              
              <List dense={true}>
                {
                  MEMBERSHIP_BENEFITS.map(benefitText => {
                    return (
                      <ListItem >
                        <ListItemIcon >
                          <RadioButtonUncheckedIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary={benefitText} primaryTypographyProps={{fontSize: '1.5rem'}}/>
                      </ListItem>
                    )

                  })
                }
              </List>
            </Grid>
            

          </Stack>
        </Grid>
        <Grid xs={12}>
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={4} marginTop="1rem" marginBottom="5rem">
            <Grid xs={5}>
              <ContractorSignupForm onSubmit={onSubmit} ></ContractorSignupForm>
            </Grid>
            <Grid xs={5} spacing={2} justifyContent="center" rowSpacing={4}>
              <Typography variant="h3" component="div" gutterBottom >
                Need more information?
              </Typography>
                Set up a call with one of our team members to answer any questions you may have. <Link
                  variant="body2"
                  href={CFH_CALENDLY_LINK}
                  underline="none"
                  target="_blank"
                  rel="noopener"
                  sx={{alignItems: 'center', alignContent: 'center' }}
                >
                  
                    Find a time to speak here.
                  
                </Link>
            </Grid>
          </Stack>
        </Grid>
        {success && <PositionedSnackbar message={`Thanks! We got your info and will reach out soon!`}/>}
      </Grid>
    </>
  );
}